import { useCallback, useEffect, useState } from "react";
import Link from "next/link";
import BaseLayout from "layouts/BaseLayout";
import Emission, { EmissionRepository } from "domains/Emission";
import { Form, Button } from "react-bootstrap";
import EmissionTable from "components/emission/Table";
import EmissionDetailTable from "components/emission/DetailTable";
import useAppContext from "hooks/useAppContext";
import { Pages } from "domains/common";
import Paginate from "components/common/Paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import Meta from "components/common/Meta";
const TITLE = "レコード送信リスト";

export default function Home() {
  const { setLoad } = useAppContext();
  const [showDetailRecords, setShowDetailRecords] = useState<Boolean>(false);
  const [items, setItems] = useState<Emission[]>([]);
  const [pages, setPages] = useState<Pages | null>();
  const [page, setPage] = useState(1);
  const [archive, setArchive] = useState(false);

  const fetchItems = useCallback(
    async (p?: number) => {
      setLoad(true);
      const res = await EmissionRepository.index(false, archive, p);
      setLoad(false);

      if ("items" in res) {
        setItems(res.items);
        setPages(res.pages);
      }
    },
    [setLoad, archive]
  );

  useEffect(() => {
    fetchItems(page);
  }, [page, fetchItems]);

  const radio = (
    <div className="radio-tab-style">
      <Form.Check
        id="records-1"
        inline
        type="radio"
        label="送信レコード(管理番号単位)"
        checked={!showDetailRecords}
        disabled={!items.length}
        onChange={() => setShowDetailRecords(false)}
        className="mb-4 mb-md-0 me-0"
      />
      <Form.Check
        id="records-2"
        inline
        type="radio"
        label="送信レコード詳細"
        checked={!!showDetailRecords}
        disabled={!items.length}
        onChange={() => setShowDetailRecords(true)}
      />
    </div>
  );

  const checkArchive = (
    <Form.Check
      id="checkArchive"
      inline
      label="アーカイブ"
      checked={archive}
      onChange={() => setArchive(!archive)}
    />
  );

  const newButton = (
    <Link href="/emissions/new" passHref>
      <Button>
        <FontAwesomeIcon icon={faCirclePlus} className="me-1" />
        新規作成
      </Button>
    </Link>
  );

  const tables = !showDetailRecords ? (
    <EmissionTable
      items={items}
      detailLink={(id: string) => {
        return `/emissions/${id}`;
      }}
    />
  ) : (
    <EmissionDetailTable items={items} />
  );

  return (
    <BaseLayout>
      <Meta title={TITLE} />
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center border-bottom my-4 pt-4">
        {radio}
        <div>
          {checkArchive}
          {newButton}
        </div>
      </div>
      {items.length ? (
        <>
          {tables}
          {pages && <Paginate pages={pages} setPage={setPage} />}
        </>
      ) : (
        <div className="text-center py-5">
          {archive ? (
            <p>アーカイブはありません。</p>
          ) : (
            <>
              <p>最初のレコードを作成しましょう。</p>
              {newButton}
            </>
          )}
        </div>
      )}
    </BaseLayout>
  );
}
